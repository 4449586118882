import { ApiServiceBase } from '~/api/services/ApiServiceBase'
import type { ApiPaginatedResponse, ApiResponse } from '~/api/types/Api'
import type {
  Agreement,
  AgreementRequest,
  BalanceBlockExemption,
  BalanceBlockExemptionRequest,
  BillingItem,
  BillingItemRequest,
  BillingProfile,
  BillingProfileRequest,
  BillingProfileSyncDriversRequest,
  Booking,
  DeclineDocumentRequest,
  DeclineNewsPostRequest,
  DeclineVehicleChangeRequest,
  DispatchSystem,
  DispatchSystemRequest,
  Document,
  DocumentRequest,
  DocumentType,
  DocumentTypeRequest,
  Driver,
  DriverEarnings,
  DriverRequest,
  Fleet,
  FleetRequest,
  Invoice,
  InvoiceAdjustment,
  InvoiceAdjustmentRequest,
  InvoiceItem,
  InvoiceItemRequest,
  ManualBlock,
  ManualBlockRequest,
  NewsCategory,
  NewsCategoryRequest,
  NewsPost,
  NewsPostRequest,
  Payment,
  Payout,
  PayoutUploadRequest,
  PublishInvoicesRequest,
  Region,
  RushpayPlan,
  RushpayPlanRequest,
  UpdateAgreementDriversRequest,
  UpdateRushpayPlanDriversRequest,
  VehicleChange,
  VehicleChangeRequest,
} from '~/api/types/DriverPortal'
import type { FetchOptions } from 'ofetch'

export default class DriverPortalService extends ApiServiceBase {
  /*
   * Agreements
   */
  async getAllAgreements(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Agreement[]>> {
    return await this.call('/staff/v1/agreements', options)
  }

  async getAgreement(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Agreement>> {
    return await this.call(`/staff/v1/agreements/${id}`, options)
  }

  async createAgreement(data: AgreementRequest): Promise<ApiResponse<Agreement>> {
    return await this.call('/staff/v1/agreements', {
      method: 'post',
      body: useFormData(data),
    })
  }

  async updateAgreement(id: string | number, data: AgreementRequest): Promise<ApiResponse<Agreement>> {
    return await this.call(`/staff/v1/agreements/${id}`, {
      method: 'post',
      body: useFormData({
        ...data,
        _method: 'put',
      }),
    })
  }

  async publishAgreement(id: string | number): Promise<ApiResponse<Agreement>> {
    return await this.call(`/staff/v1/agreements/${id}/publish`, {
      method: 'post',
    })
  }

  async archiveAgreement(id: string | number): Promise<ApiResponse<Agreement>> {
    return await this.call(`/staff/v1/agreements/${id}/archive`, {
      method: 'post',
    })
  }

  async getAllDriversForAgreement(
    id: string | number,
    options?: FetchOptions<'json'>
  ): Promise<ApiPaginatedResponse<Driver[]>> {
    return await this.call(`/staff/v1/agreements/${id}/drivers`, options)
  }

  async attachDrivers(id: string | number, data: UpdateAgreementDriversRequest): Promise<void> {
    return await this.call(`/staff/v1/agreements/${id}/drivers`, {
      method: 'post',
      body: data,
    })
  }

  async detachDrivers(id: string | number, data: UpdateAgreementDriversRequest): Promise<void> {
    return await this.call(`/staff/v1/agreements/${id}/drivers`, {
      method: 'delete',
      body: data,
    })
  }

  /*
   * Balance Block Exemptions
   */
  async getAllBalanceBlockExemptions(
    options?: FetchOptions<'json'>
  ): Promise<ApiPaginatedResponse<BalanceBlockExemption[]>> {
    return await this.call('/staff/v1/balance-block-exemptions', options)
  }

  async getBalanceBlockExemption(
    id: string | number,
    options?: FetchOptions<'json'>
  ): Promise<ApiResponse<BalanceBlockExemption>> {
    return await this.call(`/staff/v1/balance-block-exemptions/${id}`, options)
  }

  async createBalanceBlockExemption(data: BalanceBlockExemptionRequest): Promise<ApiResponse<BalanceBlockExemption>> {
    return await this.call('/staff/v1/balance-block-exemptions', {
      method: 'post',
      body: data,
    })
  }

  async updateBalanceBlockExemption(
    id: string | number,
    data: BalanceBlockExemptionRequest
  ): Promise<ApiResponse<BalanceBlockExemption>> {
    return await this.call(`/staff/v1/balance-block-exemptions/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async deleteBalanceBlockExemption(id: string | number): Promise<ApiResponse<BalanceBlockExemption>> {
    return await this.call(`/staff/v1/balance-block-exemptions/${id}`, {
      method: 'delete',
    })
  }

  /*
   * Billing Items
   */
  async getAllBillingItems(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<BillingItem[]>> {
    return await this.call('/staff/v1/billing-items', options)
  }

  async getBillingItem(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<BillingItem>> {
    return await this.call(`/staff/v1/billing-items/${id}`, options)
  }

  async createBillingItem(data: BillingItemRequest): Promise<ApiResponse<BillingItem>> {
    return await this.call('/staff/v1/billing-items', {
      method: 'post',
      body: data,
    })
  }

  async updateBillingItem(id: string | number, data: BillingItemRequest): Promise<ApiResponse<BillingItem>> {
    return await this.call(`/staff/v1/billing-items/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async deleteBillingItem(id: string | number): Promise<ApiResponse<BillingItem>> {
    return await this.call(`/staff/v1/billing-items/${id}`, {
      method: 'delete',
    })
  }

  /*
   * Billing Profiles
   */
  async getAllBillingProfiles(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<BillingProfile[]>> {
    return await this.call('/staff/v1/billing-profiles', options)
  }

  async getBillingProfile(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<BillingProfile>> {
    return await this.call(`staff/v1/billing-profiles/${id}`, options)
  }

  async createBillingProfile(data: BillingProfileRequest): Promise<ApiResponse<BillingProfile>> {
    return await this.call('/staff/v1/billing-profiles', {
      method: 'post',
      body: data,
    })
  }

  async updateBillingProfile(id: string | number, data: BillingProfileRequest): Promise<ApiResponse<BillingProfile>> {
    return await this.call(`/staff/v1/billing-profiles/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async deleteBillingProfile(id: string | number): Promise<ApiResponse<BillingProfile>> {
    return await this.call(`/staff/v1/billing-profiles/${id}`, {
      method: 'delete',
    })
  }

  async syncDriversWithBillingProfile(
    id: string | number,
    data: BillingProfileSyncDriversRequest
  ): Promise<ApiResponse<BillingProfile>> {
    return await this.call(`/staff/v1/billing-profiles/${id}/drivers`, {
      method: 'post',
      body: data,
    })
  }

  async getBillingProfileDrivers(
    id: string | number,
    options?: FetchOptions<'json'>
  ): Promise<ApiPaginatedResponse<Driver[]>> {
    return await this.call(`/staff/v1/billing-profiles/${id}/drivers`, options)
  }

  async processBillingProfile(id: string | number): Promise<ApiResponse<void>> {
    return await this.call(`/staff/v1/billing-profiles/${id}/process`, {
      method: 'post',
    })
  }

  /*
   * Bookings
   */
  async getAllBookings(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Booking[]>> {
    return await this.call('/staff/v1/bookings', options)
  }

  async getBooking(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Booking>> {
    return await this.call(`staff/v1/bookings/${id}`, options)
  }

  /*
   * Dispatch Systems
   */
  async getAllDispatchSystems(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<DispatchSystem[]>> {
    return await this.call('/staff/v1/dispatch-systems', options)
  }

  async getDispatchSystem(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<DispatchSystem>> {
    return await this.call(`/staff/v1/dispatch-systems/${id}`, options)
  }

  async createDispatchSystem(data: DispatchSystemRequest): Promise<ApiResponse<DispatchSystem>> {
    return await this.call('/staff/v1/dispatch-systems', {
      method: 'post',
      body: data,
    })
  }

  async updateDispatchSystem(id: string | number, data: DispatchSystemRequest): Promise<ApiResponse<DispatchSystem>> {
    return await this.call(`/staff/v1/dispatch-systems/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async deleteDispatchSystem(id: string | number): Promise<ApiResponse<DispatchSystem>> {
    return await this.call(`/staff/v1/dispatch-systems/${id}`, {
      method: 'delete',
    })
  }

  /*
   * Documents
   */
  async getAllDocuments(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Document[]>> {
    return await this.call('/staff/v1/documents', options)
  }

  async createDocument(data: DocumentRequest): Promise<ApiResponse<Document>> {
    return await this.call('/staff/v1/documents', {
      method: 'post',
      body: useFormData(data),
    })
  }

  async getDocument(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Document>> {
    return await this.call(`/staff/v1/documents/${id}`, options)
  }

  async updateDocument(id: string | number, data: DocumentRequest): Promise<ApiResponse<Document>> {
    return await this.call(`/staff/v1/documents/${id}`, {
      method: 'post',
      body: useFormData({
        ...data,
        _method: 'put',
      }),
    })
  }

  async deleteDocument(id: string | number): Promise<ApiResponse<Document>> {
    return await this.call(`/staff/v1/documents/${id}`, {
      method: 'delete',
    })
  }

  async approveDocument(id: string | number): Promise<ApiResponse<Document>> {
    return await this.call(`/staff/v1/documents/${id}/approve`, {
      method: 'post',
    })
  }

  async declineDocument(id: string | number, data: DeclineDocumentRequest): Promise<ApiResponse<Document>> {
    return await this.call(`/staff/v1/documents/${id}/decline`, {
      method: 'post',
      body: data,
    })
  }

  /*
   * Document Types
   */
  async getAllDocumentTypes(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<DocumentType[]>> {
    return await this.call('/staff/v1/document-types', options)
  }

  async createDocumentType(data: DocumentTypeRequest): Promise<ApiResponse<DocumentType>> {
    return await this.call('/staff/v1/document-types', {
      method: 'post',
      body: data,
    })
  }

  async getDocumentType(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<DocumentType>> {
    return await this.call(`/staff/v1/document-types/${id}`, options)
  }

  async updateDocumentType(id: string | number, data: DocumentTypeRequest): Promise<ApiResponse<DocumentType>> {
    return await this.call(`/staff/v1/document-types/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async deleteDocumentType(id: string | number): Promise<ApiResponse<DocumentType>> {
    return await this.call(`/staff/v1/document-types/${id}`, {
      method: 'delete',
    })
  }

  /*
   * Drivers
   */
  async getAllDrivers(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Driver[]>> {
    return await this.call('/staff/v1/drivers', options)
  }

  async getDriver(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Driver>> {
    return await this.call(`/staff/v1/drivers/${id}`, options)
  }

  async updateDriver(id: string | number, data: DriverRequest): Promise<ApiResponse<Driver>> {
    return await this.call(`/staff/v1/drivers/${id}`, {
      method: 'put',
      body: data,
    })
  }

  /*
   * Driver Earnings
   */
  async getAllEarnings(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<DriverEarnings[]>> {
    return await this.call(`/staff/v1/earnings`, options)
  }

  async getEarnings(driverId: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<DriverEarnings>> {
    return await this.call(`/staff/v1/earnings/${driverId}`, options)
  }

  /*
   * Fleets
   */
  async getAllFleets(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Fleet[]>> {
    return await this.call('/staff/v1/fleets', options)
  }

  async getFleet(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Fleet>> {
    return await this.call(`/staff/v1/fleets/${id}`, options)
  }

  async createFleet(data: FleetRequest): Promise<ApiResponse<Fleet>> {
    return await this.call('/staff/v1/fleets', {
      method: 'post',
      body: data,
    })
  }

  async updateFleet(id: string | number, data: FleetRequest): Promise<ApiResponse<Fleet>> {
    return await this.call(`/staff/v1/fleets/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async deleteFleet(id: string | number): Promise<ApiResponse<Fleet>> {
    return await this.call(`/staff/v1/fleets/${id}`, {
      method: 'delete',
    })
  }

  /*
   * Invoices
   */
  async getAllInvoices(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Invoice[]>> {
    return await this.call('/staff/v1/invoices', options)
  }

  async getInvoice(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Invoice>> {
    return await this.call(`/staff/v1/invoices/${id}`, options)
  }

  async deleteInvoice(id: string | number): Promise<ApiResponse<Invoice>> {
    return await this.call(`/staff/v1/invoices/${id}`, {
      method: 'delete',
    })
  }

  async publishInvoices(data: PublishInvoicesRequest): Promise<{
    processed: (number | string)[]
    skipped: (number | string)[]
  }> {
    return await this.call(`/staff/v1/invoices/publish`, {
      method: 'post',
      body: data,
    })
  }

  async publishAllInvoices(): Promise<{
    processed: (number | string)[]
  }> {
    return await this.call(`/staff/v1/invoices/publish-all`, {
      method: 'post',
    })
  }

  /*
   * Invoice adjustments
   */
  async getAllInvoiceAdjustments(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<InvoiceAdjustment[]>> {
    return await this.call('/staff/v1/invoice-adjustments', options)
  }

  async getInvoiceAdjustment(
    id: string | number,
    options?: FetchOptions<'json'>
  ): Promise<ApiResponse<InvoiceAdjustment>> {
    return await this.call(`/staff/v1/invoice-adjustments/${id}`, options)
  }

  async createInvoiceAdjustment(data: InvoiceAdjustmentRequest): Promise<ApiResponse<InvoiceAdjustment>> {
    return await this.call('/staff/v1/invoice-adjustments', {
      method: 'post',
      body: data,
    })
  }

  async updateInvoiceAdjustment(
    id: string | number,
    data: InvoiceAdjustmentRequest
  ): Promise<ApiResponse<InvoiceAdjustment>> {
    return await this.call(`/staff/v1/invoice-adjustments/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async deleteInvoiceAdjustment(id: string | number): Promise<ApiResponse<InvoiceAdjustment>> {
    return await this.call(`/staff/v1/invoice-adjustments/${id}`, {
      method: 'delete',
    })
  }

  async approveInvoiceAdjustment(id: string | number): Promise<ApiResponse<InvoiceAdjustment>> {
    return await this.call(`/staff/v1/invoice-adjustments/${id}/approve`, {
      method: 'post',
    })
  }

  async declineInvoiceAdjustment(id: string | number): Promise<ApiResponse<InvoiceAdjustment>> {
    return await this.call(`/staff/v1/invoice-adjustments/${id}/decline`, {
      method: 'post',
    })
  }

  /*
   * Invoice items
   */
  async getInvoiceItem(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<InvoiceItem>> {
    return await this.call(`/staff/v1/invoice-items/${id}`, options)
  }

  async updateInvoiceItem(id: string | number, data: InvoiceItemRequest): Promise<ApiResponse<InvoiceItem>> {
    return await this.call(`/staff/v1/invoice-items/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async deleteInvoiceItem(id: string | number): Promise<ApiResponse<InvoiceItem>> {
    return await this.call(`/staff/v1/invoice-items/${id}`, {
      method: 'delete',
    })
  }

  /*
   * Manual blocks
   */
  async getAllManualBlocks(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<ManualBlock[]>> {
    return await this.call('/staff/v1/manual-blocks', options)
  }

  async getManualBlock(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<ManualBlock>> {
    return await this.call(`/staff/v1/manual-blocks/${id}`, options)
  }

  async createManualBlock(data: ManualBlockRequest): Promise<ApiResponse<ManualBlock>> {
    return await this.call('/staff/v1/manual-blocks', {
      method: 'post',
      body: data,
    })
  }

  async deleteManualBlock(id: string | number): Promise<void> {
    return await this.call(`/staff/v1/manual-blocks/${id}`, {
      method: 'delete',
    })
  }

  /*
   * News categories
   */
  async getAllNewsCategories(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<NewsCategory[]>> {
    return await this.call('/staff/v1/news-categories', options)
  }

  async getNewsCategory(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<NewsCategory>> {
    return await this.call(`/staff/v1/news-categories/${id}`, options)
  }

  async createNewsCategory(data: NewsCategoryRequest): Promise<ApiResponse<NewsCategory>> {
    return await this.call('/staff/v1/news-categories', {
      method: 'post',
      body: data,
    })
  }

  async updateNewsCategory(id: string | number, data: NewsCategoryRequest): Promise<ApiResponse<NewsCategory>> {
    return await this.call(`/staff/v1/news-categories/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async deleteNewsCategory(id: string | number): Promise<ApiResponse<NewsCategory>> {
    return await this.call(`/staff/v1/news-categories/${id}`, {
      method: 'delete',
    })
  }

  /*
   * News posts
   */
  async getAllNewsPosts(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<NewsPost[]>> {
    return await this.call('/staff/v1/news-posts', options)
  }

  async createNewsPost(data: NewsPostRequest): Promise<ApiResponse<NewsPost>> {
    return await this.call('/staff/v1/news-posts', {
      method: 'post',
      body: useFormData(data),
    })
  }

  async getNewsPost(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<NewsPost>> {
    return await this.call(`/staff/v1/news-posts/${id}`, options)
  }

  async updateNewsPost(id: string | number, data: NewsPostRequest): Promise<ApiResponse<NewsPost>> {
    return await this.call(`/staff/v1/news-posts/${id}`, {
      method: 'post',
      body: useFormData({
        ...data,
        _method: 'put',
      }),
    })
  }

  async deleteNewsPost(id: string | number): Promise<ApiResponse<NewsPost>> {
    return await this.call(`/staff/v1/news-posts/${id}`, {
      method: 'delete',
    })
  }

  async submitNewsPost(id: string | number): Promise<ApiResponse<NewsPost>> {
    return await this.call(`/staff/v1/news-posts/${id}/submit`, {
      method: 'post',
    })
  }

  async publishNewsPost(id: string | number): Promise<ApiResponse<NewsPost>> {
    return await this.call(`/staff/v1/news-posts/${id}/publish`, {
      method: 'post',
    })
  }

  async declineNewsPost(id: string | number, data: DeclineNewsPostRequest): Promise<ApiResponse<NewsPost>> {
    return await this.call(`/staff/v1/news-posts/${id}/decline`, {
      method: 'post',
      body: data,
    })
  }

  /*
   * Regions
   */
  async getAllRegions(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Region[]>> {
    return await this.call('/staff/v1/regions', options)
  }

  /*
   * Payments
   */
  async getAllPayments(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Payment[]>> {
    return await this.call('/staff/v1/payments', options)
  }

  async getPayment(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Payment>> {
    return await this.call(`/staff/v1/payments/${id}`, options)
  }

  async resendSuccessNotification(id: string | number): Promise<void> {
    return await this.call(`/staff/v1/payments/${id}/resend-success-notification`, {
      method: 'post',
    })
  }

  /*
   * Payouts
   */
  async getAllPayouts(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Payout[]>> {
    return await this.call('/staff/v1/payouts', options)
  }

  async downloadPayouts(options?: FetchOptions<'blob'>): Promise<Blob> {
    return await this.call('/staff/v1/payouts', useMerge(options, { query: { download: true } }))
  }

  async getPayout(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Payout>> {
    return await this.call(`/staff/v1/payouts/${id}`, options)
  }

  async downloadPayoutImportTemplate(options?: FetchOptions<'blob'>): Promise<Blob> {
    return await this.call('/staff/v1/payouts/import/template', options)
  }

  async uploadPayoutImport(data: PayoutUploadRequest): Promise<any> {
    return await this.call(`/staff/v1/payouts/import/${data.fleet_id}`, {
      method: 'post',
      body: useFormData(data),
    })
  }

  /*
   * Rushpay plans
   */
  async getAllPlans(
    fleetId: string | number,
    options?: FetchOptions<'json'>
  ): Promise<ApiPaginatedResponse<RushpayPlan[]>> {
    return await this.call(`/staff/v1/fleets/${fleetId}/rushpay-plans`, options)
  }

  async getPlan(
    rushpayPlanId: string | number,
    fleetId: string | number,
    options?: FetchOptions<'json'>
  ): Promise<ApiPaginatedResponse<RushpayPlan>> {
    return await this.call(`/staff/v1/fleets/${fleetId}/rushpay-plans/${rushpayPlanId}`, options)
  }

  async createPlan(fleetId: string | number, data: RushpayPlanRequest): Promise<ApiResponse<RushpayPlan>> {
    return await this.call(`/staff/v1/fleets/${fleetId}/rushpay-plans`, {
      method: 'post',
      body: data,
    })
  }

  async deletePlan(rushpayPlanId: string | number, fleetId: string | number): Promise<ApiResponse<RushpayPlan>> {
    return await this.call(`/staff/v1/fleets/${fleetId}/rushpay-plans/${rushpayPlanId}`, {
      method: 'delete',
    })
  }

  async attachDriverToPlan(
    fleetId: string | number,
    rushpayPlanId: string | number,
    data: UpdateRushpayPlanDriversRequest
  ): Promise<ApiResponse<RushpayPlan>> {
    return await this.call(`/staff/v1/fleets/${fleetId}/rushpay-plans/${rushpayPlanId}/drivers`, {
      method: 'post',
      body: data,
    })
  }

  async detachDriverToPlan(
    fleetId: string | number,
    rushpayPlanId: string | number,
    data: UpdateRushpayPlanDriversRequest
  ): Promise<ApiResponse<RushpayPlan>> {
    return await this.call(`/staff/v1/fleets/${fleetId}/rushpay-plans/${rushpayPlanId}/drivers`, {
      method: 'delete',
      body: data,
    })
  }

  async getAllDriversForPlan(
    rushpayPlanId: string | number,
    fleetId: string | number,
    options?: FetchOptions<'json'>
  ): Promise<ApiPaginatedResponse<Driver[]>> {
    return await this.call(`/staff/v1/fleets/${fleetId}/rushpay-plans/${rushpayPlanId}/drivers`, options)
  }

  /*
   * Vehicle changes
   */
  async getAllVehicleChanges(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<VehicleChange[]>> {
    return await this.call('/staff/v1/vehicle-changes', options)
  }

  async getVehicleChange(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<VehicleChange>> {
    return await this.call(`/staff/v1/vehicle-changes/${id}`, options)
  }

  async updateVehicleChange(id: string | number, data: VehicleChangeRequest): Promise<ApiResponse<VehicleChange>> {
    return await this.call(`/staff/v1/vehicle-changes/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async approveVehicleChange(id: string | number): Promise<ApiResponse<VehicleChange>> {
    return await this.call(`/staff/v1/vehicle-changes/${id}/approve`, {
      method: 'post',
    })
  }

  async declineVehicleChange(
    id: string | number,
    data: DeclineVehicleChangeRequest
  ): Promise<ApiResponse<VehicleChange>> {
    return await this.call(`/staff/v1/vehicle-changes/${id}/decline`, {
      method: 'post',
      body: data,
    })
  }
}
